<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<style>
    @import url('https://fonts.cdnfonts.com/css/bernier-distressed');
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    .container{
        max-width: 1190px;
        padding: 0 15px;
        margin: 0 auto;
    }
    body{
        font-family: 'Oswald', sans-serif;
        color: #332D29;
    }
    button{
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        color: #332D29;
        font-family: 'Oswald', sans-serif;
        font-size: 16px;
    }
    .heading{
        font-size: 40px;
        font-family: 'BERNIER Distressed', sans-serif;
        color: #332D29;
        font-weight: 400;
        text-align: center;
    }
    a{
        color: #332D29;
    }
    .offset{
        margin-bottom: 90px;
    }
    input{
        border: none;
        outline: none;
        font-family: 'Oswald', sans-serif;
    }
    .input,
    textarea{
        color: #332D29;
        background-color: #EEEDEC;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 300;
        font-family: 'Oswald', sans-serif;
    }
    .input__offset{
        margin-bottom: 20px;
    }
    .yellow-btn{
        background-color: #FFBA73;
        text-transform: uppercase;
        border-radius: 55px;
        transition: all linear .2s;
    }
    .yellow-btn:hover{
        background-color: #F5A451;
    }
     /* Радиокнопки */
    .controls-radio__btn:checked + .controls-radio__label::after{
        transform: scale(1);
    }
    .controls-radio__label{
        position: relative;
        display: inline-block;
        padding-left: 40px;
        cursor: pointer;
    }
    .controls-radio__label::before{
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid #332D29;
        left: 0;
        top: 0;
    }
    .controls-radio__label::after{
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #332D29;
        left: 4px;
        top: 4px;
        transition: all linear .2s;
        transform: scale(0);
    }
    .controls-radio__btn{
        display: none;
    }
    @media (max-width: 600px) {
        .container{
            padding: 0 10px;
        }
        .offset{
            margin-bottom: 50px;
        }
        .heading{
            font-size: 36px;
        }
    }
</style>
