import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
      path: '/',
      name: 'main-page',
      component: () => import('@/components/user/Main-page')
    },
    {
      path: '/account-main-page',
      name: 'account-main-page',
      component: () => import('@/components/account/pages/Account-main-page')
    },
    {
      path: '/admin-main-page',
      name: 'admin-main-page',
      component: () => import('@/components/admin/pages/Admin-main-page')
    },
    {
      path: '/admproduct',
      name: 'admproduct',
      component:()=> import('@/components/admin/pages/Product-page') 
    },
    {
      path: '/confidential',
      name: 'confidential',
      component:()=> import('@/components/user/Confidential-page') 
    },
    {
      path: '/success',
      name: 'success',
      component:()=> import('@/components/user/Success-page') 
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        return {el: to.hash, behavior: 'smooth'}
    } else {
        return { x: 0, y: 0 }
    }
},
})

export default router
