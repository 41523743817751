import { createStore } from 'vuex'
import axios from "axios"

export default createStore({
    state: {
        products: [],
        orders: '',
        categories: ['Хлеб', 'Салаты', 'Выпечка', 'Десерты', 'Фермерская продукция', 'Печенье', 'Безглютеновая продукция'],
        currentProduct: {},
        selectedCategogy: 'Безглютеновая продукция',
        mainProductPhoto: '',
        searchValue: '',
        page: 0,
        cardsPerPage: 9,
        totalPages: 0,
        cart: []
    },
    getters: {
        PRODUCTS(state){
            return state.products;
        },
        TOTAL_PAGES(state){
            return state.totalPages
        },
        CURRENT_PAGE(state){
            return state.page
        },
        CATEGORIES(state){
            return state.categories
        },
        SELECTED_CATEGORY(state){
            return state.selectedCategogy
        },
        SELECTED_PRODUCT(state){
            return state.currentProduct
        },
        MAIN_PHOTO(state){
            return state.mainProductPhoto
        },
        CART(state){
            return state.cart
        },
        ORDERS(state){
            return state.orders
        }
    },
    mutations: {
        SET_PRODUCTS_TO_STATE: (state, products)=>{
            state.products = products
        },
        SET_MORE_PRODUCTS: (state, products)=>{
            state.products = state.products.concat(products.rows)
        },
        SET_SELECTED_CATEGORY: (state, category)=>{
            state.selectedCategogy = category
        },
        SET_ONE_PRODUCT: (state, data)=>{
            state.currentProduct = data
            state.mainProductPhoto = data.url
        },
        SET_MAIN_PHOTO: (state, url)=>{
            state.mainProductPhoto = url
        },
        SET_CART: (state, product)=>{
            if(state.cart.length){
                let isProductExist = false;
                state.cart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.cart.push(product)
                }
            }else{
                state.cart.push(product)
            }
        },
        REMOVE_FROM_CART: (state, index)=>{
            state.cart.splice(index, 1)
        },
        INCREASE: (state, index)=>{
            state.cart[index].quantity++
        },
        DECREASE: (state, index)=>{
            if (state.cart[index].quantity > 1){
                state.cart[index].quantity--
            }
        },
        REMOVE_PRODUCTS: (state)=>{
            state.products = []
        },
        SET_TOTAL_PAGES: (state, value)=>{
            state.totalPages = Math.ceil(value / state.cardsPerPage)
        },
        SET_DEFAULT_PAGE_NUMBER: (state, value)=>{
            state.page = value
        },
        SET_SEARCH_VALUE: (state, value) => {
            state.searchValue = value
        },
        REMOVE_SEARCH_VALUE: (state)=>{
            state.searchValue = ''
        },
        SET_ORDERS_TO_STATE: (state, orders)=>{
            state.orders = orders
        }
    },
    actions: {
        GET_MORE_PRODUCTS({commit}){
            if(this.state.searchValue != ''){
                return axios({
                    url: 'https://pekarnyada.ru:8443/api/goods/search',
                    method: "GET",
                    params:{
                        searchVal: this.state.searchValue,
                        page: ++this.state.page
                    }
                }).then((products)=>{
                    commit('SET_MORE_PRODUCTS', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }
            return axios({
                url: 'https://pekarnyada.ru:8443/api/goods/sort',
                method: "GET",
                params:{
                    category: this.state.selectedCategogy,
                    page: ++this.state.page
                }

            }).then((products)=>{
                commit('SET_MORE_PRODUCTS', products.data);
                commit('SET_TOTAL_PAGES', products.data.count);
            })
        },
        GET_ORDERS({commit}){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://pekarnyada.ru:8443/api/orders',
            {headers: {Authorization: `Bearer ${cookie[1]}`}
            }).then((res)=>{
                commit('SET_ORDERS_TO_STATE', res.data.rows)
                // this.orders = res.data.rows
            })
        },
        GET_SORTED_ORDERS({commit}, status){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://pekarnyada.ru:8443/api/orders/workstatus', 
            {headers: {Authorization: `Bearer ${cookie[1]}`},
            params:{
                workstatus: status
            }}).then((res)=>{
                commit('SET_ORDERS_TO_STATE', res.data.rows)
            })

        },
        GET_ONE_PRODUCT({commit}, data){
            commit('SET_ONE_PRODUCT', data)
        },
        SELECT_CATEGORY({commit}, value){
            commit('SET_SELECTED_CATEGORY', value)
        },
        ADD_TO_CART({commit}, product){
            commit('SET_CART', product);
        },
        INCREASE_QTY({commit}, index){
            commit('INCREASE', index)
        },
        DECREASE_QTY({commit}, index){
            commit('DECREASE', index)
        },
        DELETE_FROM_CART({commit}, index){
            commit('REMOVE_FROM_CART', index)
        },
        SELECT_MAIN_PHOTO({commit}, url){
            commit('SET_MAIN_PHOTO', url)
        },
        GET_SEARCH_VALUE({commit}, value){
            commit('SET_SEARCH_VALUE', value)
        },
        RESET_SEARCH_VALUE({commit}){
            commit('REMOVE_SEARCH_VALUE')
        },
        RESET_PAGE_NUMBER({commit}){
            if(this.state.page >= 1){
                commit('REMOVE_PRODUCTS')
            }
            commit('SET_DEFAULT_PAGE_NUMBER', 0)
        }
    }
})
